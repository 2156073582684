<template>
  <v-dialog
    v-model="dialogNovaVenda"
    :fullscreen="fullscreen"
    @click:outside="fecharVendaDialog()"
    @keydown.esc="fecharVendaDialog()"
    max-width="1500px"
    persistent
  >
    <v-card :loading="loading" :disabled="loading" tile>
      <v-card-title
        class="pt-3 pb-4 px-5 text-subtitle-1 secondary white--text"
      >
        <v-row class="d-flex aling-center">
          <v-col cols="12" md="4" class="d-flex align-center">
            <v-autocomplete
              v-model="selectedCliente"
              :items="clientes"
              :loading="buscandoClientes"
              :search-input.sync="searchCliente"
              dense
              dark
              no-filter
              item-text="nome"
              item-value="id"
              :placeholder="$tc('global.buscarCliente')"
              append-icon=""
              full-width
              hide-details
              return-object
              :disabled="venda_items.length >= 1 && !editarCliente || selectedProduto != null && !editarCliente"
              :autofocus="!selectedCliente ? true : false"
            >
              <template v-slot:selection="data">
                <div class="pa-0">
                  <div class="">{{ data.item.nome }}</div>
                  <div class="text-caption">{{ data.item.doc_ruc }}</div>
                </div>
              </template>
            </v-autocomplete>
            <v-btn
              @click="historicoCliente"
              :disabled="!selectedCliente"
              icon
              dark
              small
              fab
            >
              <v-icon>mdi-information-outline</v-icon>
            </v-btn>
            <v-tooltip bottom color="darkblue">
              <template v-slot:activator="{ on }">
                <v-btn v-on="on"
                  @click="editCliente"
                  icon
                  dark
                  small
                  fab
                >
                  <v-icon>mdi-autorenew</v-icon>
                </v-btn>
              </template> 
              <span>Alterar cliente</span>
            </v-tooltip>
            <DialogHistorticoCliente
              v-if="dialogHistoricoCliente"
              :dialogHistoricoCliente.sync="dialogHistoricoCliente"
              :cliente_id="cliente.id"
              :tipo_venda="tipoVenda"
              :selectedEndereco.sync="selectedEndereco"
            />
          </v-col>

          <v-col cols="12" md="3">
            <v-combobox
              v-model="selectedLista"
              :items="listas_precos"
              :label="$tc('global.listadepreco')"
              item-text="descricao"
              item-value="id"
              dark
              :disabled="
                !selectedCliente ||
                venda_items.length >= 1 ||
                selectedProduto != null
              "
              class="px-4 mt-0"
              hide-details
            >
            </v-combobox>
          </v-col>
          <v-col cols="12" md="2">
            <v-combobox
              v-model="selectedMoeda"
              :items="moedas"
              :label="$tc('global.moeda')"
              item-text="descricao"
              item-value="id"
              :disabled="
                !selectedCliente ||
                venda_items.length >= 1 ||
                selectedProduto != null
              "
              dark
              class="px-4 mt-0"
              hide-details
            >
            </v-combobox>
          </v-col>
          <v-col cols="12" md="3">
            <v-combobox
              v-model="selectedDeposito"
              :label="$tc('global.deposito', 2)"
              :items="depositos"
              item-text="deposito"
              item-value="deposito_id"
              dark
              class="px-4 mt-0"
              :disabled="venda_items.length >= 1 || selectedProduto != null"
              hide-details
              required
            ></v-combobox>
          </v-col>
        </v-row>
        <v-spacer></v-spacer>

        <v-btn
          v-if="editar"
          dark
          icon
          :disabled="venda.possui_contrato ? false : true"
        >
          <v-icon color="">mdi-file-sign</v-icon>
        </v-btn>

        <v-btn dark icon @click="fullscreen = !fullscreen">
          <v-icon>
            {{ !fullscreen ? "mdi-fullscreen" : "mdi-fullscreen-exit" }}
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text style="flex-grow: 1" class="pa-0 d-flex">
        <div style="flex-grow: 1">
          <v-data-table
            :headers="venda_items_headers"
            :items="venda_items"
            :items-per-page="-1"
            disable-sort
            fixed-header
            :height="
              !fullscreen ? 'calc(550px - 215px)' : 'calc(100vh - 230px)'
            "
            class="data-tables row-pointer"
            :item-class="row_classes"
            hide-default-footer
            :key="key"
            @contextmenu:row="rightClickHandler"
          >
            <template v-slot:item.img="{ item }">
              <div class="my-1">
                <v-avatar v-if="item.img">
                  <v-img max-width="65px" :src="item.img"></v-img>
                </v-avatar>
                <v-avatar v-else>
                  <v-img
                    max-width="65px"
                    src="@/assets/product-placeholder.jpg"
                  ></v-img>
                </v-avatar>
              </div>
            </template>
            <template v-slot:item.qtde="{ item, index }">
              <template
                v-if="isVendaItemEditing && index === indexVendaItemEditing"
              >
                <div class="d-flex align-center justify-center">
                  <v-text-field
                    v-model.number="vendaItemEditing.qtde"
                    :placeholder="$tc('global.qtd')"
                    outlined
                    dense
                    class="input-low-height input-center"
                    hide-details
                    append-outer-icon="mdi-plus"
                    @click:append-outer="vendaItemEditing.qtde++"
                    prepend-icon="mdi-minus"
                    @click:prepend="
                      vendaItemEditing.qtde > 1 ? vendaItemEditing.qtde-- : ''
                    "
                  ></v-text-field>
                </div>
              </template>
              <template v-else>
                {{ item.qtde }}
              </template>
            </template>

            <template v-slot:item.preco="{ item, index }">
              <div
                v-show="isVendaItemEditing && index === indexVendaItemEditing"
              >
                <div class="d-flex align-center justify-center">
                  <v-text-field
                    v-model="vendaItemEditingPreco"
                    :placeholder="$tc('global.preco')"
                    hide-details
                    outlined
                    dense
                    class="input-low-height"
                    ref="vendaItemEditingPreco"
                    :error="invalidPrecoItemEdit"
                    :append-icon="
                      invalidPrecoItemEdit ? 'mdi-lock-alert-outline' : ''
                    "
                    @click:append="openDialogAuth(true)"
                    v-currency="vCurrencyOptions"
                    @keydown.enter.prevent="UpdateVendaItem(index)"
                  ></v-text-field>
                  <v-tooltip bottom color="darkblue">
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" size="small" class="ml-1">mdi-information-variant </v-icon>
                    </template>
                    <span>{{ $tc("global.precoMinimo") }}</span>
                    <span v-if="selectedMoeda && selectedMoeda.sigla === 'G$'">
                      {{ item.preco_minimo | guarani }}
                    </span>
                    <span v-else>{{ item.preco | currency }} </span>
                  </v-tooltip>
                  <v-tooltip v-if="item.preco_alterado" bottom color="darkblue">
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" size="large" class="ml-1"  :color="controlePrecos[index].preco_alterado ? 'error' : 'success'" >mdi-cash-lock</v-icon>
                    </template>
                    <span v-show="controlePrecos[index].preco_alterado">
                      {{ $tc("global.necessarioConfirmarPreco") }} <br/>
                    </span>
                    <span>
                      {{ $tc("global.precoNovoCliente") }}
                      <span v-if="selectedMoeda && selectedMoeda.sigla === 'G$'">
                      {{ item.novo_preco | guarani }}
                      </span>
                      <span v-else>{{ item.novo_preco | currency }} </span>
                    </span>
                  </v-tooltip>
                </div>
              </div>
              <div
                v-show="!isVendaItemEditing && index != indexVendaItemEditing"
              >
                <span v-if="selectedMoeda && selectedMoeda.sigla === 'G$'">
                  {{ item.preco | guarani }}
                </span>
                <span v-else>{{ item.preco | currency }} </span>
                <v-tooltip bottom color="darkblue">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" size="small" class="ml-1">mdi-information-variant </v-icon>
                  </template>
                  <span>{{ $tc("global.precoMinimo") }}</span>
                  <span v-if="selectedMoeda && selectedMoeda.sigla === 'G$'">
                    {{ item.preco_minimo | guarani }}
                  </span>
                  <span v-else>{{ item.preco_minimo | currency }} </span>
                </v-tooltip>
                <v-tooltip v-if="item.preco_alterado" bottom color="darkblue">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" size="large" class="ml-1" :color="controlePrecos[index].preco_alterado ? 'error' : 'success'">mdi-cash-lock</v-icon>
                  </template>
                  <span v-show="controlePrecos[index].preco_alterado">
                    {{ $tc("global.necessarioConfirmarPreco") }} <br/>
                  </span>
                  <span>
                    {{ $tc("global.precoNovoCliente") }}
                    <span v-if="selectedMoeda && selectedMoeda.sigla === 'G$'">
                    {{ item.novo_preco | guarani }}
                    </span>
                    <span v-else>{{ item.novo_preco | currency }} </span>
                  </span>
                </v-tooltip>
              </div>
            </template>

            <template v-slot:item.subtotal="{ item, index }">
              <template
                v-if="isVendaItemEditing && index === indexVendaItemEditing"
              >
                <span v-if="selectedMoeda && selectedMoeda.sigla === 'G$'">
                  {{
                    (vendaItemEditing.qtde * vendaItemEditing.preco) | guarani
                  }}
                </span>
                <span v-else
                  >{{
                    (vendaItemEditing.qtde * vendaItemEditing.preco) | currency
                  }}
                </span>
              </template>
              <template v-else>
                <span v-if="selectedMoeda && selectedMoeda.sigla === 'G$'">
                  {{ (item.qtde * item.preco) | guarani }}
                </span>
                <span v-else>{{ (item.qtde * item.preco) | currency }} </span>
              </template>
            </template>

            <template v-slot:item.actions="{ item, index }">
              <template
                v-if="isVendaItemEditing && index === indexVendaItemEditing"
              >
                <v-btn
                  color="green lighten-1"
                  icon
                  small
                  @click.stop="UpdateVendaItem(index)"
                >
                  <v-icon size="20">mdi-content-save</v-icon>
                </v-btn>
              </template>
              <template v-else>
                <v-tooltip bottom color="darkblue">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-if="controlePrecos && controlePrecos[index].preco_alterado"
                      icon
                      small
                      :disabled="editar ? !PVendaEditar : false"
                      @click.stop="confirmarMudancaPreco(index)"
                      v-on="on" 
                    >
                      <v-icon size="20">mdi-check-bold</v-icon>
                    </v-btn>
                  </template>
                  <span>
                    {{ $tc("global.confirmar") + " " + $tc("global.preco") }}
                  </span>
                </v-tooltip>
                <v-btn
                  icon
                  small
                  :disabled="editar ? !PVendaEditar : false"
                  @click.stop="StartEditVendaItem(item, index)"
                >
                  <v-icon size="20">mdi-pencil-outline</v-icon>
                </v-btn>
                <v-btn
                  color="red lighten-2"
                  icon
                  small
                  :disabled="editar ? !PVendaEditar : false"
                  @click.stop="DeleteVendaItem(index, item)"
                >
                  <v-icon size="20">mdi-delete-outline</v-icon>
                </v-btn>
              </template>
            </template>
          </v-data-table>
          <div
            v-if="cambio"
            style="position: relative"
            class="tocs_gray_1 d-flex align-center flex-wrap pa-2"
          >
            <v-row dense no-gutters>
              <v-col cols="12" md="2">
                <v-card outlined class="pa-1 d-flex align-center">
                  <span class="pl-2 text-caption">Dia</span>
                  <v-spacer></v-spacer>
                  <span class="pr-2 text-caption">
                    {{ cambio.created_at | dateFormat("dd/MM") }}
                  </span>
                </v-card>
              </v-col>
              <v-col cols="12" md="2">
                <v-card outlined class="pa-1 d-flex align-center">
                  <span class="pl-2 text-caption">G$ -> U$</span>
                  <v-spacer></v-spacer>
                  <span class="pr-2 text-caption">
                    {{ cambio.guarani | guarani }}
                  </span>
                </v-card>
              </v-col>
              <v-col cols="12" md="2">
                <v-card outlined class="pa-1 d-flex align-center">
                  <span class="pl-2 text-caption">G$ -> R$</span>
                  <v-spacer></v-spacer>
                  <span class="pr-2 text-caption">
                    {{ cambio.guarani_real | guarani }}
                  </span>
                </v-card>
              </v-col>
              <v-col cols="12" md="2">
                <v-card outlined class="pa-1 d-flex align-center">
                  <span class="pl-2 text-caption">R$</span>
                  <v-spacer></v-spacer>
                  <span class="pr-2 text-caption">
                    {{ cambio.real_venda | currency }}
                  </span>
                </v-card>
              </v-col>
              <v-col cols="12" md="2">
                <v-card outlined class="pa-1 d-flex align-center">
                  <span class="pl-2 text-caption"
                    >G$ {{ $tc("global.credito") }}</span
                  >
                  <v-spacer></v-spacer>
                  <span class="pr-2 text-caption">
                    {{ cambio.guarani_credito | guarani }}
                  </span>
                </v-card>
              </v-col>
              <v-col cols="12" md="2">
                <v-card outlined class="pa-1 d-flex align-center">
                  <span class="pl-2 text-caption"
                    >G$ {{ $tc("global.debito") }}</span
                  >
                  <v-spacer></v-spacer>
                  <span class="pr-2 text-caption">
                    {{ cambio.guarani_debito | guarani }}
                  </span>
                </v-card>
              </v-col>
            </v-row>
          </div>
          <div
            style="position: relative"
            class="tocs_gray_1 d-flex align-center flex-wrap pa-2"
          >
            <!-- Busca Produto -->
            <SearchProdutosMultiDeposito
              :produto_sync.sync="selectedProduto"
              :lista_preco_id="venda.lista_preco_id"
              :deposito_id="venda.deposito_id"
              :deposito_descricao="
                selectedDeposito ? selectedDeposito.deposito : ''
              "
              :moeda_id="venda.moeda_id"
              tipo="simples"
              @clear-add-item="ClearAddVendaItem"
              ref="buscaProduto"
              typeComp="venda"
              style="max-width: 45%"
              :disableAll="
                (editar ? !PVendaEditar : '') ||
                !selectedCliente ||
                desabilitarEdicao
                  ? true
                  : false
              "
            />

            <!-- Quantidade -->
            <v-text-field
              v-model.number="venda_item.qtde"
              :placeholder="$tc('global.qtd')"
              hide-details
              dense
              solo
              flat
              outlined
              class="mx-3 input-center"
              style="max-width: 15%"
              append-outer-icon="mdi-plus"
              @click:append-outer="venda_item.qtde++"
              :disabled="
                (editar ? !PVendaEditar : '') ||
                !selectedCliente ||
                desabilitarEdicao
                  ? true
                  : false
              "
              prepend-icon="mdi-minus"
              @click:prepend="venda_item.qtde > 1 ? venda_item.qtde-- : ''"
            ></v-text-field>
            <!-- Preço -->
            <v-text-field
              v-model="vendaItemPreco"
              :placeholder="$tc('global.preco')"
              hide-details
              dense
              solo
              flat
              outlined
              class="mr-3"
              style="max-width: 15%"
              :append-icon="invalidPrecoItem ? 'mdi-lock-alert-outline' : ''"
              @click:append="openDialogAuth(false)"
              :error-messages="erroPrecoItem"
              @keydown.enter.prevent="validaValorProduto"
              ref="vendaItemPreco"
              :disabled="(editar ? !PVendaEditar : '') || !selectedCliente"
              v-currency="vCurrencyOptions"
            >
              <template v-slot:append-item>
                <v-btn text block class="primary--text">
                  <v-icon left>mdi-plus</v-icon>
                  {{ $tc("global.add") + " Pais" }}
                </v-btn>
              </template>
            </v-text-field>

            <div
              v-if="
                selectedProduto && estoque_disponivel != null && !loadingEstoque
              "
            >
              <div v-if="estoque_disponivel > 0">
                <div class="text-caption">
                  {{ $tc("global.saldo") }} {{ $tc("global.disponivel") }}
                </div>

                <div class="text-caption">
                  {{ estoque_disponivel }}
                </div>
              </div>

              <div v-if="estoque_disponivel <= 0">
                <div class="text-caption red--text">
                  {{ $tc("global.produto") }} sem
                </div>
                <div class="text-caption red--text">
                  {{ $tc("global.saldo") }} {{ $tc("global.disponivel") }}
                </div>
              </div>
            </div>

            <div v-else-if="loadingEstoque">
              <v-progress-circular indeterminate></v-progress-circular>
            </div>

            <v-btn
              depressed
              color="secondary"
              height="38px"
              absolute
              right
              :disabled="(editar ? !PVendaEditar : '') || disableAddVendaItem"
              :loading="loadingAddVendaItem"
              @click="validaValorProduto"
            >
              <v-icon color="white">mdi-plus</v-icon>
            </v-btn>
          </div>
        </div>
        <div
          style="
            width: 200px;
            border-left: 1px solid rgba(0, 0, 0, 0.12) !important;
          "
          class="tocs_gray_1 d-flex flex-column align-stretch justify-space-between"
        >
          <!-- Totais -->
          <div
            class="px-2 fill-height d-flex flex-column align-stretch justify-end"
          >
            <!-- Credito -->
            <div
              v-if="
                selectedCliente &&
                selectedMoeda &&
                selectedCliente.moeda_sigla === selectedMoeda.sigla &&
                credito_cliente > 0
              "
              class="pb-5"
            >
              <h3 class="text-subtitle-1 pb-0 mb-n4 tocs_gray_2--text">
                Crédito Cliente :
              </h3>
              <h3 class="text-h5 text-right pt-4">
                <v-sheet
                  rounded
                  outlined
                  max-height="40px"
                  class="blue-grey--text d-flex align-center justify-space-between text--lighten-1 pr-4"
                >
                  <span class="ml-3 subtitle-1">
                    {{ selectedCliente ? selectedCliente.moeda_sigla : "" }}
                  </span>

                  <span
                    class="ml-3 subtitle-1"
                    v-if="
                      selectedCliente
                        ? selectedCliente.moeda_sigla === 'G$'
                        : ''
                    "
                    >{{ credito_cliente | guarani }}
                  </span>
                  <span v-else class="ml-3 subtitle-1"
                    >{{ credito_cliente | currency }}
                  </span>
                </v-sheet>
              </h3>
            </div>
            <!-- SubTotal -->
            <div class="pb-5">
              <h3 class="text-subtitle-1 pb-0 mb-n4 tocs_gray_2--text">
                Subtotal {{ $tc("global.geral") }}:
              </h3>
              <h3 class="text-h5 text-right pt-4">
                <v-sheet
                  rounded
                  outlined
                  max-height="40px"
                  class="blue-grey--text d-flex align-center justify-space-between text--lighten-1 pr-4"
                >
                  <span class="ml-3 subtitle-1">
                    {{ selectedMoeda ? selectedMoeda.sigla : "" }}
                  </span>

                  <span
                    class="ml-3 subtitle-1"
                    v-if="selectedMoeda ? selectedMoeda.sigla === 'G$' : ''"
                    >{{ subTotalGeral | guarani }}
                  </span>
                  <span class="ml-3 subtitle-1" v-else
                    >{{ subTotalGeral | currency }}
                  </span>
                </v-sheet>
              </h3>
            </div>

            <!-- Desconto -->
            <div class="pb-5">
              <h3
                class="text-subtitle-1 tocs_gray_2--text d-flex justify-space-between"
              >
                <div class="d-flex justify-space-between">
                  <span class="">{{ $tc("global.desconto") }}</span>
                  <span
                    :class="{ 'blue--text': !descontoPorcentagem }"
                    class="ml-1 mr-1 subtitle-1"
                  >
                    {{ selectedMoeda ? selectedMoeda.sigla : "" }}
                  </span>
                  <v-switch
                    v-model="descontoPorcentagem"
                    :false-value="false"
                    hide-details
                    flat
                    dense
                    class="pa-0 ma-0 mx-1"
                    :true-value="true"
                  >
                  </v-switch>
                  <span
                    :class="{ 'blue--text': descontoPorcentagem }"
                    class="subtitle-1"
                  >
                    %
                  </span>
                </div>
              </h3>
              <v-text-field
                v-show="!descontoPorcentagem"
                ref="inputDesconto"
                v-model="vendaDesconto"
                dense
                :prefix="selectedMoeda ? selectedMoeda.sigla : ''"
                outlined
                solo
                flat
                :error-messages="erroDesconto"
                max-height="40px"
                style="display: flex"
                class="remove-underline input-font-size input-right"
                :disabled="
                  (editar ? !PVendaEditar : '') ||
                  desabilitarEdicao ||
                  descontoPorcentagem
                "
                v-currency="vCurrencyOptions"
                @blur="validaDesconto"
              ></v-text-field>

              <v-text-field
                v-show="descontoPorcentagem"
                ref="inputDescontoPorcentagem"
                v-model="vendaDescontoPorcentagem"
                dense
                type="number"
                label="%"
                flat
                outlined
                :error-messages="erroDescontoPorcentagem"
                max-height="40px"
                v-mask="['###']"
                style="display: flex"
                solo
                class="input-font-size"
                :disabled="(editar ? !PVendaEditar : '') || desabilitarEdicao"
                @blur="validaDescontoPorcentagem"
                @keydown.enter="validaDescontoPorcentagem"
              >
                <template
                  v-if="valorDescontoPorcentagemCalculado > 0"
                  v-slot:append
                >
                  <v-chip small v-if="selectedMoeda.sigla === 'G$'">
                    {{ selectedMoeda.sigla }}
                    {{ valorDescontoPorcentagemCalculado | guarani }}
                  </v-chip>
                  <v-chip small v-else>
                    {{ selectedMoeda.sigla }}
                    {{ valorDescontoPorcentagemCalculado | currency }}
                  </v-chip>
                </template>
              </v-text-field>
            </div>

            <!-- Total -->
            <div class="mt-0 mb-4">
              <h3 class="text-h6 tocs_gray_2--text">
                {{ $tc("global.total") }}:
              </h3>
              <h3 class="text-h5 white--text">
                <v-sheet
                  rounded
                  outlined
                  height="40px"
                  class="blue-grey--text d-flex align-center justify-space-between text--darken-3 pr-4"
                >
                  <span class="ml-3 subtitle-1">
                    {{ selectedMoeda ? selectedMoeda.sigla : "" }}
                  </span>
                  <span v-if="selectedMoeda ? selectedMoeda.sigla === 'G$' : ''"
                    >{{ totalGeral | guarani }}
                  </span>
                  <span v-else>{{ totalGeral | currency }} </span>
                </v-sheet>
              </h3>
            </div>
          </div>
        </div>
      </v-card-text>

      <!-- Botoes De Ação -->
      <v-card-actions class="py-3 px-5 justify-space-between">
        <!-- <div v-if="editar">
          <v-btn
            class="secondary white--text"
            depressed
            small
            outlined
            @click="$emit('update:dialogNovaVenda', false)"
          >
            <v-icon left small class="mx-1">mdi-arrow-left</v-icon>
            Voltar
          </v-btn>
        </div> -->

        <div>
          <v-btn
            class="error white--text"
            depressed
            small
            outlined
            @click="dialogCancelarVenda = true"
          >
            <v-icon left small class="mx-1">mdi-close</v-icon>
            Fechar
          </v-btn>
          <!-- Confirmar Cancelamento -->
          <v-dialog persistent v-model="dialogCancelarVenda" max-width="400px">
            <v-card>
              <v-card-title dark class="align-center tocs_gray_1 subtitle-1">
                Deseja Fechar essa venda?
              </v-card-title>
              <v-divider></v-divider>
              <div v-if="!editar" class="text-center my-8">
                <span> Caso feche esta tela todos dados serão perdidos! </span>
              </div>
              <!-- <div v-else class="text-center my-8">
                <span>
                  Caso feche e existem dados modificados não serão salvos
                </span>
              </div> -->
              <v-card-actions>
                <v-btn
                  color="tocs_gray_2"
                  text
                  depressed
                  @click="dialogCancelarVenda = false"
                >
                  Cancelar
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  color="error"
                  depressed
                  @click="$emit('update:dialogNovaVenda', false)"
                >
                  <v-icon left small class="mx-1">mdi-close</v-icon>
                  Fechar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>

        <div
          v-if="editar && !loading"
          class="d-flex text-caption font-weight-black"
        >
          <div v-if="venda.vendedor_nome">
            {{ `${venda.vendedor_nome} - ` }}
            {{ venda.created_at | dateFormat("dd/MM/yyyy - HH:mm:ss") }}
          </div>

          <div v-show="showPrecoProdutos" class="pl-5">
            <span v-if="selectedMoeda ? selectedMoeda.sigla === 'G$' : ''">
              {{ valorProdutoPiscineiro | guarani }} /
              {{ `${valorProdutoVarejo}` | guarani }}
            </span>
            <span v-else>
              {{ valorProdutoPiscineiro | currency }} /
              {{ `${valorProdutoVarejo}` | currency }}</span
            >
          </div>
        </div>

        <div>
          <v-btn
            v-if="!desabilitarEdicao"
            depressed
            small
            color="button_2"
            class="white--text"
            :loading="loadingVenda"
            @click="editar ? salvarVenda(true) : criarVenda(true)"
            :disabled="
              (editar ? !PVendaEditar : false) ||
              invalidDesconto ||
              venda_items.length <= 0 ||
              itensPendentes > 0
            "
          >
            <v-icon left small class="mx-1">mdi-content-save</v-icon>
            Salvar
          </v-btn>
          <!-- Finalizar Venda -->
          <v-btn
            v-if="!desabilitarEdicao"
            depressed
            small
            color="button_1"
            class="white--text ml-2"
            :loading="loadingVenda"
            :disabled="
              (editar ? !PVendaEditar : false) ||
              invalidDesconto ||
              venda_items.length <= 0 ||
              itensPendentes > 0
            "
            @click="openDialogEnviarCaixa"
          >
            <v-icon left small class="mx-1">mdi-cash-register</v-icon>
            {{ $tc("global.enviarCaixa") }}
          </v-btn>

          <DialogEnviarCaixa
            v-if="dialogEnviarCaixa"
            :dialogEnviarCaixa.sync="dialogEnviarCaixa"
            :venda_id.sync="item_venda_id ? item_venda_id : venda_id"
            @enviar-caixa="enviarCaixa()"
          />

          <DialogAuth
            v-if="dialogAuth"
            :dialogAuth.sync="dialogAuth"
            :authTabela="authTabela"
            @add-produto="AddVendaItem"
            @edit-produto="UpdateVendaItemAuth"
          />
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  searchClientes,
  searchClientesVendas,
  clienteInfosVenda,
  fetchClienteCredito,
} from "@/api/clientes/clientes.js";
import { fetchLPCompletas } from "@/api/listas-precos/listasprecos.js";
import { postEstoqueMinimo, getOneProduto } from "@/api/produtos/produtos.js";
import { mapState, mapGetters } from "vuex";
import { postVenda, putVenda, fetchVenda, checkPrecoItems } from "@/api/vendas/vendas.js";
import { fetchVendasItems } from "@/api/vendas/vendas_items.js";
import { fetchUsersDeposito } from "@/api/usuarios/usuarios.js";

export default {
  name: "NovaVenda",

  props: {
    dialogNovaVenda: {
      type: Boolean,
      default: false,
    },
    editar: {
      type: Boolean,
      default: false,
    },
    item_venda_id: {
      type: [String, Number],
    },
    orcamento: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    DialogEnviarCaixa: () => import("./DialogEnviarCaixa.vue"),
    DialogHistorticoCliente: () => import("./DialogHistoricoCliente.vue"),
    DialogAuth: () => import("./DialogAuth.vue"),
    SearchProdutosMultiDeposito: () =>
      import("@/components/fields/SearchProdutosMultiDeposito.vue"),
  },

  data() {
    return {
      tipoVenda: "SIMPLES",
      vendaDescontoPorcentagem: 0,
      editarCliente: false,
      oldListaId: null,
      controlePrecos: [],
      key: 0,
      loadingVenda: false,
      valorDescontoPorcentagemCalculado: 0,
      descontoPorcentagem: false,
      venda: {
        desconto: 0,
      },
      fullscreen: false,
      loading: true,
      selectedCliente: null,
      clientes: [],
      buscandoClientes: false,
      searchCliente: "",
      listas_precos: [],
      selectedLista: null,
      selectedMoeda: null,
      selectedProduto: null,
      venda_items: [],
      venda_item: {
        qtde: 1,
      },
      vendaItemPreco: null,
      loadingAddVendaItem: false,
      isVendaItemEditing: false,
      vendaItemEditingPreco: null,
      vendaItemEditing: null,
      indexVendaItemEditing: null,
      erroDesconto: "",
      erroDescontoPorcentagem: "",
      vendaDesconto: 0,
      dialogCancelarVenda: false,
      invalidDesconto: false,
      dialogHistoricoCliente: false,
      cliente: {},
      estoque_disponivel: null,
      loadingEstoque: false,
      limite_estoque: false,
      dialogEnviarCaixa: false,
      selectedDeposito: null,
      depositos: [],
      venda_id: null,
      erroPrecoItem: "",
      invalidPrecoItem: false,
      invalidPrecoItemEdit: false,
      dialogAuth: false,
      authTabela: false,
      hasDevolucao: false,
      credito_cliente: 0,
      valorProdutoVarejo: 0,
      valorProdutoPiscineiro: 0,
      showPrecoProdutos: false,
      selectedEndereco: null,
      debounce: null,
    };
  },

  computed: {
    ...mapState("Moedas", {
      moedas: (state) => state.moedas,
    }),

    ...mapState("Usuario", {
      user_id: (state) => state.usuario.id,
      usuario: (state) => state.usuario,
    }),

    ...mapState("Cambio", {
      cambio: (state) => state.cambio,
    }),

    ...mapGetters("Componentes", ["getAccess"]),
    PVendaEditar() {
      return this.getAccess("Vendas", "editar");
    },

    itensPendentes() {
      return this.controlePrecos.filter((item) => item.preco_alterado).length;
    },

    disableAddVendaItem() {
      let result = false;
      if (this.selectedProduto == null) {
        result = true;
      }

      return result;
    },

    vCurrencyOptions() {
      return {
        precision: this.selectedMoeda
          ? this.selectedMoeda.sigla === "G$"
            ? 0
            : undefined
          : "",
        distractionFree: false,
      };
    },
    vCurrencyOptionsPorcentagem() {
      return {
        precision: 0,
        distractionFree: false,
        min: 0,
        max: 100,
      };
    },

    subTotalGeral() {
      let result = 0;

      if (this.venda_items.length) {
        const reducer = (accumulator, currentValue) => {
          return accumulator + currentValue.preco * currentValue.qtde;
        };

        result = this.venda_items.reduce(reducer, 0);
      }
      return result;
    },

    totalGeral() {
      let result = 0;

      if (this.venda_items.length && this.venda) {
        result = this.subTotalGeral - Number(this.venda.desconto);
      }

      return result;
    },

    venda_items_headers() {
      let headers = [
        {
          text: "",
          divider: true,
          width: "70px",
          value: "img",
        },
        {
          text: this.$tc("global.produto"),
          divider: true,
          value: "descricao",
        },
        {
          text: "Qtde",
          divider: true,
          width: "150px",
          value: "qtde",
          align: "center",
        },
        {
          text: this.$tc("global.preco"),
          divider: true,
          width: "150px",
          value: "preco",
          align: "end",
        },
        {
          text: "SubTotal",
          divider: true,
          width: "150px",
          value: "subtotal",
          align: "end",
        },
      ];
      if (!this.desabilitarEdicao) {
        let actions = {
          text: "",
          width: "120px",
          align: "center",
          value: "actions",
        };

        headers.push(actions);
      }

      if (this.hasDevolucao) {
        let devolucao = {
          text: "Qtde " + this.$tc("global.devolucao"),
          width: "100px",
          align: "center",
          divider: true,
          value: "qtde_devolucao",
        };

        headers.splice(3, 0, devolucao);
      }

      return headers;
    },

    desabilitarEdicao() {
      if (
        this.venda.status == 2 ||
        this.venda.status == -1 ||
        this.venda.status == 3 ||
        this.venda.status == 5
      ) {
        return true;
      } else {
        return false;
      }
    },
  },

  watch: {
    selectedEndereco() {
      if (this.selectedEndereco) {
        this.cliente.endereco_principal = this.selectedEndereco;
      }
    },
    descontoPorcentagem() {
      this.venda.desconto = 0;
      this.vendaDescontoPorcentagem = 0;
      this.vendaDesconto = 0;
    },
    async selectedCliente() {
      if (this.selectedCliente || this.venda.cliente_id) {
        if (this.selectedCliente) {
          this.venda.cliente_id = this.selectedCliente.id;
        }
        if (this.selectedCliente) {
          if (!("endereco_principal" in this.selectedCliente)) {
            await this.clienteInfos(this.selectedCliente.id);
          }
          this.selectedLista = this.listas_precos.find(
            (lista) => lista.id === this.selectedCliente.lista_preco_id
          );

          this.selectedMoeda = this.moedas.find(
            (moeda) => moeda.id_moeda === this.selectedCliente.moeda_id
          );
        }
        if (this.selectedCliente) {
          this.getClienteCredito();
        }
        this.$nextTick().then(() => {
          this.$refs.buscaProduto.$refs.buscaProduto.focus();
        });
        if (this.editarCliente) {
          this.editarCliente = false;
          let newListaId = this.selectedCliente.lista_preco_id;
          let data = {
            old_lista_preco_id: this.oldListaId,
            new_lista_preco_id: newListaId,
            venda_items: this.venda_items,
            moeda_id: this.venda.moeda_id,
          };
          const response = await checkPrecoItems(data);
          const responseCopy = JSON.parse(JSON.stringify(response));
          this.controlePrecos = responseCopy;
          this.venda_items = response;
          this.oldListaId = newListaId;
          this.key++;
        }
    }
  },

    selectedLista() {
      if (this.selectedLista) {
        this.venda.lista_preco_id = this.selectedLista.id;
      }
    },

    selectedMoeda() {
      if (this.selectedMoeda) {
        this.venda.moeda_id = this.selectedMoeda.id_moeda;
      }
    },

    selectedProduto() {
      if (this.selectedProduto) {
        this.getProduto();
      }
    },

    vendaItemPreco() {
      this.venda_item.preco = this.$ci.parse(
        this.vendaItemPreco,
        this.vCurrencyOptions
      );
    },

    vendaItemEditingPreco() {
      if (this.isVendaItemEditing) {
        this.vendaItemEditing.preco = this.$ci.parse(
          this.vendaItemEditingPreco,
          this.vCurrencyOptions
        );
      }
    },

    selectedDeposito() {
      if (this.selectedDeposito) {
        this.venda.deposito_id = this.selectedDeposito.deposito_id;
      }
    },

    totalGeral() {
      this.venda.valor = this.subTotalGeral;
      this.venda.total = this.totalGeral;
    },

    searchCliente() {
      if (this.buscandoClientes) return;
      this.buscaClientes();
    },
    venda_id() {
      if (this.venda_id && !this.editar) {
        this.venda.id = this.venda_id;
      }
    },
  },

  methods: {
    fecharVendaDialog() {
      this.dialogCancelarVenda = true;
    },

    confirmarMudancaPreco(index){
      this.controlePrecos[index].preco_alterado = false;
      this.key++;
    },

    editCliente(){
      this.editarCliente = true;
      this.oldListaId = this.selectedCliente.lista_preco_id;
      this.key++;
    },

    async rightClickHandler(event, item) {
      event.preventDefault();
      const response = await fetchLPCompletas(
        `?status=1&produto_id=${item.item.id}`
      );
      response.forEach((lista_preco) => {
        if (lista_preco.descricao == "VAREJO") {
          lista_preco.items.forEach((preco_produto) => {
            if (preco_produto.moeda_id == this.selectedMoeda.id_moeda) {
              this.valorProdutoVarejo = preco_produto.valor;
            }
          });
        }
        if (lista_preco.descricao == "PISCINEIRO") {
          lista_preco.items.forEach((preco_produto) => {
            if (preco_produto.moeda_id == this.selectedMoeda.id_moeda) {
              this.valorProdutoPiscineiro = preco_produto.valor;
            }
          });
        }
      });
      this.showPrecoProdutos = true;
      setTimeout(() => (this.showPrecoProdutos = false), 2000);
    },
    getClienteCredito() {
      this.loading = true;
      fetchClienteCredito(this.selectedCliente.id)
        .then((response) => {
          this.credito_cliente = response;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    row_classes(linha) {
      if (
        this.venda_items.filter((x) => x.produto_id === linha.produto_id)
          .length > 1
      ) {
        return "tocs_red--text";
      }
      if (linha.preco_alterado){
        return "tocs_orange--text";
      }
    },

    async getProduto() {
      let filtro = `?lista_preco_id=${this.selectedLista.id}`;
      filtro += `&moeda_id=${this.selectedMoeda.id_moeda}`;

      const produto = await getOneProduto(this.selectedProduto.id, filtro);

      if (!produto) {
        this.$toast.warning(
          "Produto sem preço registrado nesta lista e com essa moeda!"
        );
        this.ClearAddVendaItem();
        return;
      }

      this.$ci.setValue(this.$refs.vendaItemPreco, Number(produto.preco));

      this.venda_item.cif = Number(produto.cif);
      this.venda_item.produto_id = produto.id;
      this.venda_item.descricao = produto.descricao;
      this.venda_item.preco_minimo = produto.preco_minimo;
      this.venda_item.controle_estoque = produto.controle_estoque;
      if (produto.img) {
        this.venda_item.img = produto.img;
      }
      if (produto.controle_estoque == 1) {
        await this.controleEstoque(produto.id, this.venda_item.qtde);
      } else {
        this.estoque_disponivel = null;
      }

      this.$refs.vendaItemPreco.focus();
    },

    async openDialogEnviarCaixa() {
      this.loadingVenda = true;
      if (!this.venda_items.length) {
        this.$toast.error(
          "Adicione items a lista de venda antes de finalizar a venda!"
        );
      }
      if (!this.venda.id && !this.edit) {
        await this.criarVenda();
        this.dialogEnviarCaixa = true;
      } else {
        await this.salvarVenda();
        this.dialogEnviarCaixa = true;
      }
      this.loadingVenda = false;
    },

    async getDepositos() {
      this.loading = true;
      return fetchUsersDeposito(`?users_id=${this.user_id}`)
        .then((response) => {
          this.depositos = response;

          this.selectedDeposito = this.depositos.find((deposito) => {
            return deposito.principal == true;
          });
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    historicoCliente() {
      this.dialogHistoricoCliente = true;
      this.cliente = this.selectedCliente;
    },

    async searchClientesVenda(filtro) {
      this.buscandoClientes = true;

      return searchClientesVendas(filtro).then((response) => {
        this.clientes = response;
        this.buscandoClientes = false;
      });
    },

    // async buscaClientes() {
    //   if (this.searchCliente) {
    //     if (this.searchCliente.length > 2) {
    //       await this.searchClientesVenda(`?search=${this.searchCliente}`);
    //     }
    //   }
    // },

    async buscaClientes() {
      const filtro = `?search=${this.searchCliente}`;

      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        await this.searchClientesVenda(this.searchCliente ? filtro : "");
      }, 500);
    },

    async getListasPrecos() {
      // this.loading = true;
      return fetchLPCompletas()
        .then((response) => {
          this.listas_precos = response;
          // this.loading = false;
        })
        .catch((error) => {
          // this.loading = false;
        });
    },

    limiteEstoqueAdd(id, qtde, controle_estoque) {
      if (controle_estoque == 0) {
        return false;
      } else {
        let totalVendaItems = 0;

        let itemsSelecionado = this.venda_items.filter(
          (item) => item.produto_id === id
        );

        const reducer = (accumulator, currentValue) => {
          return accumulator + Number(currentValue.qtde);
        };

        totalVendaItems = itemsSelecionado.reduce(reducer, 0);

        if (this.isVendaItemEditing) {
          let verificaLimite =
            this.estoque_disponivel -
            (totalVendaItems -
              this.venda_items[this.indexVendaItemEditing].qtde) -
            qtde;

          verificaLimite =
            verificaLimite +
            Number(this.venda_items[this.indexVendaItemEditing].qtde);

          return verificaLimite < 0 ? true : false;
        } else {
          let verificaLimite = this.estoque_disponivel - qtde;

          return verificaLimite < 0 ? true : false;
        }
      }
    },

    AddVendaItem() {
      this.loadingAddVendaItem = true;

      let limite_estoque = this.limiteEstoqueAdd(
        this.venda_item.produto_id,
        this.venda_item.qtde,
        this.venda_item.controle_estoque
      );

      if (this.venda_item.qtde >= 0 && this.vendaItemPreco && !limite_estoque) {
        let venda_item = { ...this.venda_item };
        this.venda_items.push(venda_item);

        this.$nextTick().then(() => {
          this.ClearAddVendaItem();
          this.$refs.buscaProduto.$refs.buscaProduto.focus();
        });

        this.invalidPrecoItem = false;
        this.invalidPrecoItemEdit = false;
        this.invalidDesconto = false;
        this.erroPrecoItem = "";
        this.loadingAddVendaItem = false;
      }
      if (!this.vendaItemPreco) {
        this.$toast.error(
          "Favor inserir preço antes de adicionar um item na lista!"
        );
        this.loadingAddVendaItem = false;
      }
      if (!this.venda_item.qtde || this.venda_item.qtde <= 0) {
        this.$toast.error("Quantidade minima para adicionar o item é 1!");
        this.venda_item.qtde = 1;
        this.loadingAddVendaItem = false;
      }
      if (limite_estoque) {
        this.$toast.error("Quantidade de items indisponivel no estoque!");
        this.loadingAddVendaItem = false;
      }
    },

    ClearAddVendaItem() {
      this.selectedProduto = null;
      this.venda_item.produto_id = null;
      this.venda_item.descricao = null;
      this.venda_item.qtde = 1;
      this.venda_item.preco = null;
      this.venda_item.cif = null;
      this.venda_item.img = null;

      this.$ci.setValue(this.$refs.vendaItemPreco, null);
    },

    StartEditVendaItem(item, index) {
      this.isVendaItemEditing = true;
      this.vendaItemEditing = { ...item };
      this.preco_minimo = item.preco;
      this.indexVendaItemEditing = index;
      this.controleEstoque(this.vendaItemEditing.produto_id);

      this.$ci.setValue(this.$refs.vendaItemEditingPreco, item.preco);
    },

    UpdateVendaItem(index) {
      let limite_estoque = this.limiteEstoqueAdd(
        this.venda_items[this.indexVendaItemEditing].produto_id,
        this.vendaItemEditing.qtde,
        this.venda_items[this.indexVendaItemEditing].controle_estoque
      );

      let preco_minimo = this.$ci.parse(
        this.venda_items[this.indexVendaItemEditing].preco_minimo,
        this.vCurrencyOptions
      );

      let produto_preco = (this.venda_items[this.indexVendaItemEditing].preco =
        this.vendaItemEditing.preco);

      if (
        produto_preco === null ||
        produto_preco === undefined ||
        produto_preco === ""
      ) {
        this.invalidPrecoItemEdit = true;
      } else {
        if (
          produto_preco < 0 ||
          produto_preco < preco_minimo ||
          isNaN(produto_preco)
        ) {
          this.$toast.error("Valor do produto menor que o minimo do atacado");
          this.invalidPrecoItemEdit = true;
        } else {
          if (!limite_estoque) {
            this.venda_items[this.indexVendaItemEditing].preco =
              this.vendaItemEditing.preco;
            this.venda_items[this.indexVendaItemEditing].qtde =
              this.vendaItemEditing.qtde;

            this.isVendaItemEditing = false;
            this.vendaItemEditing = null;
            this.indexVendaItemEditing = null;
          } else {
            this.$toast.error("Quantidade de items indisponivel no estoque!");
          }
        }
      }
      this.confirmarMudancaPreco(index);
    },

    UpdateVendaItemAuth() {
      let limite_estoque = this.limiteEstoqueAdd(
        this.venda_items[this.indexVendaItemEditing].produto_id,
        this.vendaItemEditing.qtde
      );

      let produto_preco = (this.venda_items[this.indexVendaItemEditing].preco =
        this.vendaItemEditing.preco);

      if (
        produto_preco === null ||
        produto_preco === undefined ||
        produto_preco === ""
      ) {
        this.invalidPrecoItemEdit = true;
      } else {
        if (!limite_estoque) {
          this.venda_items[this.indexVendaItemEditing].preco =
            this.vendaItemEditing.preco;
          this.venda_items[this.indexVendaItemEditing].qtde =
            this.vendaItemEditing.qtde;

          this.isVendaItemEditing = false;
          this.vendaItemEditing = null;
          this.indexVendaItemEditing = null;
        } else {
          this.$toast.error("Quantidade de items indisponivel no estoque!");
        }
      }
    },

    DeleteVendaItem(index) {
      this.venda_items.splice(index, 1);
    },

    validaDesconto() {
      let desconto = this.$ci.parse(this.vendaDesconto, this.vCurrencyOptions);

      if (desconto === null || desconto === undefined || desconto === "") {
        this.invalidDesconto = true;
      } else {
        if (desconto < 0 || desconto > this.subTotalGeral || isNaN(desconto)) {
          this.erroDesconto = "Valor de desconto inválido";
          this.invalidDesconto = true;
        } else {
          this.erroDesconto = "";

          this.venda.desconto = Number(desconto);

          this.invalidDesconto = false;
        }
      }
    },

    validaDescontoPorcentagem() {
      let desconto = this.$ci.parse(this.vendaDescontoPorcentagem, {
        precision: undefined,
        distractionFree: true,
      });
      if (desconto === null || desconto === undefined || desconto === "") {
        this.invalidDescontoPorcentagem = true;
      } else {
        if (desconto < 0 || desconto > 100 || isNaN(desconto)) {
          this.erroDescontoPorcentagem = "Valor de desconto inválido";
          this.invalidDescontoPorcentagem = true;
        } else {
          this.erroDescontoPorcentagem = "";
          this.valorDescontoPorcentagemCalculado =
            (this.subTotalGeral / 100) * desconto;
          this.venda.desconto = Number(this.valorDescontoPorcentagemCalculado);

          this.invalidDescontoPorcentagem = false;
        }
      }
    },

    validaValorProduto() {
      let produto_preco = this.$ci.parse(
        this.vendaItemPreco,
        this.vCurrencyOptions
      );

      if (
        produto_preco === null ||
        produto_preco === undefined ||
        produto_preco === ""
      ) {
        this.invalidPrecoItem = true;
      } else {
        if (
          produto_preco < 0 ||
          produto_preco < this.venda_item.preco_minimo ||
          isNaN(produto_preco)
        ) {
          this.$toast.error("Valor do produto menor que o minimo do atacado");
          this.erroPrecoItem = "Valor do produto menor que o minimo do atacado";

          this.invalidPrecoItem = true;
        } else {
          this.erroPrecoItem = "";
          this.invalidDesconto = false;
          this.AddVendaItem();
        }
      }
    },

    async criarVenda(buttonSave) {
      this.loadingVenda = true;

      let venda = {};
      venda.status = 1;

      venda.cliente_id = this.selectedCliente.id;
      venda.cliente_nome = this.selectedCliente.nome;
      venda.cliente_endereco = this.selectedCliente.endereco_principal;
      venda.cliente_estado = this.selectedCliente.departamento;
      venda.cliente_pais = this.selectedCliente.pais;
      venda.cliente_documento = this.selectedCliente.doc_ruc
        ? this.selectedCliente.doc_ruc
        : this.selectedCliente.doc_ci;
      venda.cliente_cidade = this.selectedCliente.cidade;

      venda.moeda_id = this.venda.moeda_id;
      venda.lista_preco_id = this.venda.lista_preco_id;
      venda.desconto = this.venda.desconto;
      venda.user_id = this.user_id;
      venda.valor = this.subTotalGeral;
      venda.total = this.totalGeral;
      venda.obs = this.venda.obs;
      venda.condicao_pagamento = this.venda.condicao_pagamento;
      venda.data_vencimento = this.venda.data_vencimento;
      venda.venda_items = this.venda_items;
      venda.deposito_id = this.venda.deposito_id;
      if (this.orcamento) {
        venda.orcamento_id = this.venda.orcamento_id;
      }

      return postVenda(venda)
        .then((response) => {
          if (response.result === "success") {
            if (this.orcamento) {
              this.$emit("fecha-dialog");
              this.$emit("fecth-orcamentos");
            } else {
              if (buttonSave) {
                this.$emit("update:dialogNovaVenda", false);
                this.$emit("fetch-vendas");
              }
              this.venda_id = response.venda_id;
              this.loadingVenda = false;
            }
          } else {
            this.loadingVenda = false;
            this.$toast.error(this.$tc("global.produtosemsaldo"));
          }
        })
        .finally(() => {
          this.loadingVenda = false;
        });
    },

    async salvarVenda(buttonSave) {
      this.loadingVenda = true;

      let venda = {};
      venda.status = 1;
      if (this.selectedCliente) {
        venda.cliente_id = this.selectedCliente.id;
        venda.cliente_nome = this.selectedCliente.nome;
        venda.cliente_endereco = this.selectedCliente.endereco_principal;
        venda.cliente_estado = this.selectedCliente.departamento;
        venda.cliente_pais = this.selectedCliente.pais;
        venda.cliente_documento = this.selectedCliente.doc_ruc
          ? this.selectedCliente.doc_ruc
          : this.selectedCliente.doc_ci;
        venda.cliente_cidade = this.selectedCliente.cidade;
      }
      venda.moeda_id = this.venda.moeda_id;
      venda.lista_preco_id = this.venda.lista_preco_id;
      venda.desconto = this.venda.desconto;
      venda.user_id = this.user_id;
      venda.valor = this.subTotalGeral;
      venda.total = this.totalGeral;
      venda.obs = this.venda.obs;
      venda.condicao_pagamento = this.venda.condicao_pagamento;
      venda.data_vencimento = this.venda.data_vencimento;
      venda.venda_items = this.venda_items;
      venda.deposito_id = this.venda.deposito_id;
      if (this.orcamento) {
        venda.orcamento_id = this.venda.orcamento_id;
      }

      return putVenda(this.venda.id, venda).then((response) => {
        this.loadingVenda = false;
        if (buttonSave) {
          this.$emit("update:dialogNovaVenda", false);
          this.$emit("fetch-vendas");
        }
      });
    },

    atualizarVenda() {
      this.loadingVenda = true;
      let venda = {};
      venda.cliente_id = this.selectedCliente.id;
      venda.cliente_nome = this.selectedCliente.nome;
      venda.cliente_endereco = this.selectedCliente.endereco_principal;
      venda.cliente_estado = this.selectedCliente.departamento;
      venda.cliente_pais = this.selectedCliente.pais;
      venda.cliente_documento = this.selectedCliente.doc_ruc
        ? this.selectedCliente.doc_ruc
        : this.selectedCliente.doc_ci;
      venda.cliente_cidade = this.selectedCliente.cidade;
      venda.moeda_id = this.venda.moeda_id;
      venda.lista_preco_id = this.venda.lista_preco_id;
      venda.desconto = this.venda.desconto;
      venda.user_id = this.user_id;
      venda.valor = this.subTotalGeral;
      venda.total = this.totalGeral;
      venda.obs = this.venda.obs;
      venda.condicao_pagamento = this.venda.condicao_pagamento;
      venda.data_vencimento = this.venda.data_vencimento;
      venda.venda_items = this.venda_items;

      putVenda(this.venda.id, venda)
        .then((response) => {
          if (response.status === 201) {
            this.loadingVenda = false;
            this.$emit("update:dialogNovaVenda", false);
            this.$emit("fetch-vendas");
          }
        })
        .finally(() => {
          this.loadingVenda = false;
        });
    },

    async searchClientesEdit(filtro) {
      return searchClientes(filtro).then((response) => {
        this.clientes = response;
      });
    },

    async initEditVenda() {
      this.loading = true;

      this.venda = await fetchVenda(this.item_venda_id);

      await this.getVendaItems();

      if (this.venda.cliente_id) {
        await this.searchClientesEdit(`?search=id:${this.venda.cliente_id}`);
        this.selectedCliente = this.clientes[0];
      }
      this.selectedDeposito = this.depositos.find((item) => {
        return item.deposito_id == this.venda.deposito_id;
      });
      this.selectedLista = this.listas_precos.find((item) => {
        return item.id == this.venda.lista_preco_id;
      });
      this.selectedMoeda = this.moedas.find((item) => {
        return item.id_moeda == this.venda.moeda_id;
      });

      this.$ci.setValue(this.$refs.inputDesconto, this.venda.desconto);
      this.selectedCliente.endereco_principal = this.venda.cliente_endereco;
      this.loading = false;
    },

    async getVendaItems() {
      const response = await fetchVendasItems(
        this.venda && this.venda.id ? this.venda.id : this.venda_id
      );
      this.venda_items = response;
      this.venda_items.find((item) => {
        if (Number(item.qtde_devolucao) > 0) {
          this.hasDevolucao = true;
        }
      });
    },

    async controleEstoque(item, qtde) {
      this.loadingEstoque = true;

      const produtoEstoque = {
        produtoId: item,
        depositoId: this.venda.deposito_id,
      };

      const response = await postEstoqueMinimo(produtoEstoque);
      this.estoque_disponivel = response.data.result;
      this.loadingEstoque = false;
    },

    enviarCaixa() {
      this.$emit("update:dialogNovaVenda", false);
      this.$emit("fetch-vendas");
    },

    openDialogAuth(authTabela) {
      this.authTabela = authTabela;
      this.dialogAuth = true;
    },

    async clienteInfos(cliente_id) {
      const response = await clienteInfosVenda(cliente_id);
      this.selectedCliente = response;
    },
  },

  async mounted() {
    await this.getListasPrecos();
    await this.getDepositos();

    if (this.editar) {
      await this.initEditVenda();
    } else {
      await this.searchClientesVenda(`?search=SIN NOMBRE`);
      this.selectedCliente = this.clientes[0];
    }
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
.remove-underline ::v-deep .v-input__slot::before {
  border-style: none !important;
}
.input-right ::v-deep input {
  text-align: right;
}
.input-font-size ::v-deep input {
  font-size: 1.5rem;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
